<template>

  <div class="">
    <custom-alert v-if="displayAlert==true" :message="alertMessage"
                  v-on:showedAlert="resetAlert"></custom-alert>
    <!-- Content Header (Page header) -->
    <div class="">
      <!-- <button class="btn btn-primary" @click="showText= !showText">Click Me</button> -->
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Main row -->
          <div class="row">
            <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
              <div class="card ">
                <div class="card-body table-responsive table-head">
                  <div class="row bor-bot">
                    <div class="col-md-8 pl-0">
                      <h1 class="m-0 text-dark pt-2 pb-4 ">Player Transfer Request List</h1>
                    </div>
                    <div class="col-md-4">
                      <div class="text-right pb-3">
                        <ol class="breadcrumb float-sm-right">
                          <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                          <li class="breadcrumb-item">
                            <Breadcrumbs/>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="card-body pt-0 table-responsive">
                    <b-table
                        id="my-table"
                        :items="transferEnable"
                        :fields="tblTransferFields"
                        :busy="!loaded"
                        class="mt-3"
                        hover
                        show-empty
                    >
                      <template
                          :fields="items"
                      >

                      </template>
                      <template #empty="scope">
                        <h4>{{ scope.emptyText }}</h4>
                      </template>

                      <template #cell(id)>
                        {{'Player Transfer'}}
                      </template>
                      <template #cell(flag)="row">
                        {{row.item.flag == 1 ? 'Enabled': 'Disabled'}}
                      </template>

                      <template #cell(actions)="row">
                        <div class="text-left py-0 align-middle">
                          <div class="btn-group btn-group-sm">
                            <button  class="btn btn-primary ml-1" @click="approveForm(row.item.id)"
                            ><i
                                class="fa fa-edit"></i></button>
                          </div>
                        </div>
                      </template>
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                    </b-table>


                  </div>
                </div>
              </div>
            </section>
          </div>
          <!-- /.row (main row) -->
        </div><!-- /.container-fluid -->
      </section>
    </div>
    <b-modal id="playerTransfer" ref="playerTransfer" title="Player Transfer"
             @hidden="resetTransferForm"
             @ok="handleTransferForm"
    >
      <form ref="form">
        <b-form-group label="Player Transfer" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
              id="radio-group-1"
              v-model="transferForm.flag"
              :options="options"
              :aria-describedby="ariaDescribedby"
              name="radio-options"
          ></b-form-radio-group>
        </b-form-group>
      </form>
    </b-modal>
    <!-- /.content -->
    <Footer></Footer>
  </div>
</template>

<script>

// import RegistrationsListTable from './RegistrationsListTable';
import $ from 'jquery';
// import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
// import 'datatables.net-responsive-dt';
import moment from 'moment';
import axios from "axios";


export default {
  name: 'registrations',
  components: {},
  data() {
    return {
      tblTransferFields: [
        { key: 'id', sortable: false, label: 'Name'  },
        { key: 'flag', sortable: false, label: 'Status'  },
        { key: 'actions', sortable: false },
      ],
      transferForm: {
        id: '',
        flag: ''
      },
      options: [
        { text: 'Enable', value: 1 },
        { text: 'Disable', value: 0 },

      ],
      transferEnable:[],
      enable:'',
      loaded:true,
    }
  },
  methods: {
    formatDate(value) {
      var date = moment(value, "YYYY-MM-DD hh:mm:ss");
      if (date.isValid()) {
        return date.format('MM/DD/YYYY');
      } else {
        return 'N/A';
      }
    },
    approveForm(id) {
      this.transferForm.id = id;
      this.$refs['playerTransfer'].show();
    },
    resetTransferForm() {
      this.transferForm.id = '';
      this.transferForm.flag = this.enable;
    },
    handleTransferForm(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      axios.post(this.basePath + 'api/save/playerTransfer/enable', this.transferForm, {headers: this.adminHeaders})
          .then(function (response) {
            this.alertMessage = "Details Updated Successfully";
            this.displayAlert = true;
            this.checkTransferEnable();
            this.$refs['playerTransfer'].hide()
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
    },
    checkTransferEnable(){
      this.loaded=false;
      this.transferEnable = [];
      axios.post(this.basePath + "api/get/playerTransfer/enable", this.updInsurance, {headers: this.adminHeaders})
          .then(
              function (response) {
                const resp = response.data.data;
                this.transferForm.flag = resp.flag;
                this.enable = resp.flag;
                this.transferForm.id = resp.id;
                this.transferEnable.push(resp);
                this.loaded = true;
              }.bind(this)
          ).catch(function (error) {
        console.log(error);
      });
    },
  },
  mounted() {
    this.checkTransferEnable();
  },

  computed: {}
}
</script>